import React, {useEffect, useState} from 'react';
import {validateEmail} from '../../utils/articleUtil';
import '../../css/components/_ebook-form.scss';
import {Popup} from '@cg-squad/ui-components';
import {navigate} from 'gatsby';
import {metadata} from '../../../config';
import {STRINGS} from '../../constants/strings';
import {URL} from '../../constants/urls';

const EbookForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tnc, setTnC] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setName(params.name || '');
    setEmail(params.email || '');
  }, []);

  const onActionClick = async (action) => {
    switch (action) {
      case 'yes':
        if (validateForm()) {
          setError(null);
          setLoading(true);
          fetch(`${URL.SERVER}/subscribe?name=${name}&email=${email}&book=${props.data.tag}&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}`)
            .then(resp => resp.json())
            .then(res => {
              setLoading(false);
              setShowPopup(true);
              fbq('track', 'Lead');
            });
        }
        break;
    }
  };

  const validateForm = () => {
    if (!email || !validateEmail(email)) {
      setError('Please enter valid email');
      return false;
    }
    if (!tnc) {
      setError('Please accept Pension Times’ Terms and Privacy Policy');
      return false;
    }
    return true;
  };

  const onClosePopup = () => {
    setShowPopup(false);
    navigate(metadata.url);
  };

  return <div className="ebook-form" data-datocms-noindex>
    {showPopup && <Popup onClose={onClosePopup} onOutSideClick={() => {
    }} customClass={'subscribe-popup'}>
      <h3>
        All set, your personalised download link is being setup and sent to your email as you read
        these words :-)
      </h3>
      <h4 style={{textAlign: 'center'}}>Please check your email now to get your free eBook
        edition.</h4>
    </Popup>}
    <div className={'logo-container'}>
      <img src={'/images/logos/logo-black-700.png'} alt={'Pension Times Logo'}/>
      <hr/>
    </div>
    <div className="container">
      <div className="form-container">
        <h1>Get your eBook</h1>
        <h2>Just one more step, we need you to confirm your name & email address</h2>
        {/*<h1>{name !== '' ? name + ', j' : 'J'}ust one more step, <span className={'black'}>we need you to confirm your email address and that you are at least 18 years old.</span></h1>*/}
        <div>
          {error && <div className={`error-message`}>{error}</div>}
          <div className={'label'}>First Name</div>
          <input type="text" placeholder="Enter your first name*" value={name}
                 onChange={(event) => {
                   setError(null);
                   setName(event.target.value);
                 }}/>
          <div className={'label'}>Email</div>
          <input type="email" placeholder="Enter your email*" value={email} onChange={(event) => {
            setError(null);
            setEmail(event.target.value);
          }}/>
          <div className={'checkbox-container'}>
            <input type="checkbox" id="tnc" defaultChecked={tnc} onChange={() => setTnC(!tnc)}/>
            <label htmlFor="tnc">
              I accept and acknowledge <a href="/website-terms-of-use" target="_blank">
              Pension Times’ Terms</a> and <a href="/website-privacy-policy" target="_blank">Privacy
              Policy</a>
            </label>
          </div>
          <div className={'checkbox-container'}>
            <input type="checkbox" id="marketing" defaultChecked={marketing}
                   onChange={() => setMarketing(!marketing)}/>
            <label htmlFor="marketing">
              I’m happy for Pension Times to send me selected marketing information which may be of
              interest to me.
            </label>
          </div>
          <div className="action-container">
            <button onClick={() => {
              onActionClick('yes');
            }} className={'pt-button subscribe-button-yes ok'} disabled={loading}>
              Send me my FREE eBook
              {loading && (
                <img
                  src="/images/loading.svg"
                  style={{marginLeft: '5px'}}
                />
              )}
            </button>
          </div>
        </div>

      </div>
      <div className="book-container">
        <h1>Get your eBook</h1>
        <img src={props.data.cover} className="vert-center img-responsive book-background"
             alt={props.data.name}
             title={props.data.name}/>
      </div>
    </div>
  </div>;
};

export default EbookForm;
